import { FullCastingCallFragment, PlatformEnum } from "~/__generated__/graphql"
import {
  businessTypeName,
  categoryName,
  collaborationTypeName,
} from "~/common/enums"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { Tag } from "~/ui/Tag"
import { formatBudgetRange } from "../../util/formatBudgetRange"
import { CopyButton } from "~/ui/CopyButton"
import { hasPlatformDeliverable } from "../../util/hasPlatformDeliverable"

export const CastingCallHeading = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  return (
    <div className="flex flex-row gap-4">
      <div className="flex-grow flex flex-col gap-2">
        <div className="font-header font-bold text-2xl">
          {castingCall.campaignName}
        </div>
        <div className="text-defaultSecondary">
          {collaborationTypeName(castingCall.collaborationType)}
        </div>
        <div className="flex flex-row gap-2 items-center">
          {hasPlatformDeliverable(castingCall, PlatformEnum.TikTok) && (
            <SocialMediaIcon platform={PlatformEnum.TikTok} />
          )}
          {hasPlatformDeliverable(castingCall, PlatformEnum.Instagram) && (
            <SocialMediaIcon platform={PlatformEnum.Instagram} />
          )}
          {hasPlatformDeliverable(castingCall, PlatformEnum.Youtube) && (
            <SocialMediaIcon platform={PlatformEnum.Youtube} />
          )}
          {hasPlatformDeliverable(castingCall, PlatformEnum.Twitch) && (
            <SocialMediaIcon platform={PlatformEnum.Twitch} />
          )}
        </div>
        <div className="text-greenDark">
          {formatBudgetRange(
            castingCall.budgetMinCents,
            castingCall.budgetMaxCents
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          {castingCall.influencerCountriesData
            ?.slice(0, 4)
            .map((country) => (
              <Tag
                key={country.name}
                variant="country"
                text={`${country.emojiFlag} ${country.name}`}
              />
            ))}
          {castingCall.influencerCategories?.map((category) => (
            <Tag
              key={category}
              variant="category"
              text={categoryName(category)}
            />
          ))}
        </div>
      </div>
      {castingCall.agency && (
        <div className="text-right flex flex-col gap-1">
          <div className="font-header font-bold text-2xl">
            {castingCall.agency.name}
          </div>
          <div>
            <Tag
              variant="businessType"
              text={businessTypeName(castingCall.agency.businessType)}
            />
          </div>
          <div className="text-defaultSecondary">
            {castingCall.agency.agentName}
          </div>
          {castingCall.agency.phone && (
            <div className="text-defaultSecondary">
              {castingCall.agency.phone}
            </div>
          )}
          {castingCall.agency.contactEmail && (
            <div className="flex justify-end">
              <CopyButton
                label={castingCall.agency.contactEmail}
                textToCopy={castingCall.agency.contactEmail}
                successMessage={`"${castingCall.agency.contactEmail}" copied to clipboard`}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
