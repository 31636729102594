import { useFormContext } from "react-hook-form"
import { collaborationTypeOptions } from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import {
  FloatingLabelInput,
  FloatingLabelTextarea,
} from "~/shadcn/ui/floating-label-input"
import { FloatingLabelDateTimepicker } from "~/ui/FloatingLabelDateTimepicker"
import { IconHeading } from "~/ui/IconHeading"
import { Select } from "~/ui/Select"
import Text from "~/ui/typography"
import { moneyToCents } from "~/util/numbers"
import { CastingCallFormValues } from "../../data/castingCallSchema"

import CastingIcon from "~/images/icons/casting.svg?react"
import { CollaborationTypeEnum } from "~/__generated__/graphql"
import { DeliverablesFields } from "./DeliverablesFields"

export const CampaignDetailsFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  const collaborationType = form.watch(
    "collaborationType"
  ) as CollaborationTypeEnum | null

  return (
    <>
      <IconHeading icon={<CastingIcon />} title="Campaign Details" />
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        <FormField
          control={form.control}
          name="campaignName"
          render={({ field }) => (
            <FormItem>
              <FloatingLabelInput
                {...field}
                id="campaignName"
                label="Campaign Name*"
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="collaborationType"
          render={({ field, formState }) => (
            <FormItem>
              <Select
                {...field}
                value={collaborationTypeOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(option) => {
                  field.onChange(option?.value || "")
                }}
                options={collaborationTypeOptions}
                isClearable
                placeholder="Type Of Collaboration*"
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="influencerSubmissionDueDate"
          render={({ field }) => (
            <FormItem>
              <FloatingLabelDateTimepicker
                {...field}
                label="Influencer Submission Due Date"
              />
              <FormMessage />
              <p className="text-gray50 text-sm mt-2">
                The influencer submission due date is the last day talent
                agencies can submit influencers to this casting call. The
                casting call will be removed from the main list after this day
                has passed.
              </p>
            </FormItem>
          )}
        />
      </div>
      <div className="mt-8 mb-4">
        <Text variant="body3" className="font-bold">
          Budget
        </Text>
      </div>
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        <FormField
          control={form.control}
          name="budgetMinCents"
          render={({ field }) => (
            <FormItem>
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  label="Minimum $"
                  type="number"
                  value={field.value ? field.value / 100 : ""}
                  onChange={(e) => {
                    field.onChange(moneyToCents(e.target.value) || "")
                  }}
                  prefix="$"
                />
                <FormMessage />
              </FormItem>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="budgetMaxCents"
          render={({ field }) => (
            <FormItem>
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  label="Maximum $"
                  type="number"
                  value={field.value ? field.value / 100 : ""}
                  onChange={(e) => {
                    field.onChange(moneyToCents(e.target.value) || "")
                  }}
                  prefix="$"
                />
                <FormMessage />
              </FormItem>
            </FormItem>
          )}
        />
      </div>
      <div className="mt-4">
        <FormField
          control={form.control}
          name="budgetNotes"
          render={({ field }) => (
            <FormItem>
              <FormItem>
                <FloatingLabelTextarea {...field} label="Notes" />
                <FormMessage />
              </FormItem>
            </FormItem>
          )}
        />
      </div>
      <div className="mt-8 mb-4">
        <Text variant="body3" className="font-bold">
          Deliverables
        </Text>
      </div>
      {(!collaborationType ||
        [
          CollaborationTypeEnum.Affiliate,
          CollaborationTypeEnum.Gifting,
          CollaborationTypeEnum.Irl,
          CollaborationTypeEnum.PaidPromo,
        ].includes(collaborationType)) && <DeliverablesFields />}
      <div className="flex flex-col gap-4">
        {collaborationType === CollaborationTypeEnum.Ugc && (
          <div className="flex flex-row flex-1 gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="deliverableQuantity"
                render={({ field }) => (
                  <FormItem>
                    <FormItem>
                      <FloatingLabelInput
                        {...field}
                        label="Quantity"
                        onChange={(e) => {
                          field.onChange(parseInt(e.target.value) || "")
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1" />
            <div className="flex-1" />
          </div>
        )}
        <div className="grid grid-cols-2 gap-8">
          <FormField
            control={form.control}
            name="draftDueDate"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelDateTimepicker
                  {...field}
                  label="Draft Due Date"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="postDate"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelDateTimepicker {...field} label="Post Date" />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex-1">
          <FormField
            control={form.control}
            name="deliverableUsageRights"
            render={({ field }) => (
              <FormItem>
                <FormItem>
                  <FloatingLabelInput {...field} label="Usage Rights" />
                  <FormMessage />
                </FormItem>
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="deliverableNotes"
          render={({ field }) => (
            <FormItem>
              <FormItem>
                <FloatingLabelTextarea {...field} label="Notes" />
                <FormMessage />
              </FormItem>
            </FormItem>
          )}
        />
      </div>
    </>
  )
}
