import { Avatar } from "~/ui/Avatar"
import { AgencyFragment, PlatformEnum } from "~/__generated__/graphql"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { friendlyNumber } from "~/util/numbers"

export const AgencyHeader = ({ agency }: { agency: AgencyFragment }) => {
  return (
    <div className="flex flex-row gap-4 my-8">
      <div>
        <Avatar src={agency.logoThumbnailUrl} alt={agency.name} size="lg" />
      </div>
      <div className="flex-grow flex flex-col gap-2">
        <div className="font-header font-bold text-2xl">{agency.name}</div>
        <div className="flex flex-row gap-4 items-center">
          <SocialMediaCount
            count={agency.tiktokFollowers}
            platform={PlatformEnum.TikTok}
          />
          <SocialMediaCount
            count={agency.instagramFollowers}
            platform={PlatformEnum.Instagram}
          />
          <SocialMediaCount
            count={agency.youtubeSubscribers}
            platform={PlatformEnum.Youtube}
          />
          <SocialMediaCount
            count={agency.twitchSubscribers}
            platform={PlatformEnum.Twitch}
          />
        </div>
        <div className="text-defaultSecondary">
          {agency.influencerCount} Influencer(s)
        </div>
        {agency.website && (
          <div>
            <a
              href={agency.website}
              target="_blank"
              rel="noreferrer"
              className="text-defaultSecondary"
            >
              {agency.website.replace(/https?:\/\//i, "")}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

const SocialMediaCount = ({
  count,
  platform,
}: {
  count: string
  platform: PlatformEnum
}) => {
  const countInt = parseInt(count, 10)

  if (!countInt || countInt <= 0) {
    return null
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <div>
        <SocialMediaIcon platform={platform} />
      </div>
      <span className="text-defaultSecondary">
        {friendlyNumber(Number(count))}
      </span>
    </div>
  )
}
