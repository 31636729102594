import { FullInfluencerFragment } from "~/__generated__/graphql"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { Alert } from "~/shadcn/ui/alert"

export const InvalidUrlMessage = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  const { currentUser } = useCurrentUserMaybe()

  if (
    !currentUser ||
    (currentUser.agency &&
      influencer.agency &&
      currentUser.agency.id !== influencer.agency.id)
  ) {
    // only show message for the owners of the influencer record
    return null
  }

  return (
    <Alert variant="warning" className="py-1">
      We have detected an issue with the URL for this profile. Please update the
      URL.
    </Alert>
  )
}
