import toast from "react-hot-toast"
import {
  AdminUserEntryFragment,
  BusinessTypeEnum,
  JobTitleEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  UserAccountForm,
  UserAccountFormValues,
} from "~/screens/users/components/UserAccountForm"

import { ADMIN_UPDATE_USER_MUTATION } from "../api"

export const EditUserModal: React.FC<{
  user: AdminUserEntryFragment
  onClose: () => void
  onUserUpdated: () => void
}> = ({ user, onClose, onUserUpdated }) => {
  const [updateUser, { loading, error }] = useSafeMutation(
    ADMIN_UPDATE_USER_MUTATION
  )

  const onSubmit = async (values: UserAccountFormValues) => {
    const { data } = await updateUser({
      variables: {
        input: {
          userId: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: (values.jobTitle || null) as JobTitleEnum | null,
          notifyOfNewAgencies: values.notifyOfNewAgencies,
          agency: {
            ...values.agency,
            businessType: values.agency.businessType as BusinessTypeEnum,
          },
        },
      },
    })

    if (data?.userUpdate?.user) {
      toast.success("User updated successfully")
      onUserUpdated()
      onClose()
    }
  }

  return (
    <div className="flex flex-col flex-1">
      <h2 className="text-2xl font-bold mb-4">Edit User</h2>
      <div className="absolute top-[60px] bottom-0 left-0 right-0">
        <UserAccountForm
          initialValues={{
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            jobTitle: user.jobTitle || "",
            agency: {
              agentName: user.agency?.agentName || "",
              contactEmail: user.agency?.contactEmail || "",
              logo: undefined,
              name: user.agency?.name || "",
              phone: user.agency?.phone || "",
              website: user.agency?.website || "",
              businessType: user.agency?.businessType || "",
            },
            password: "",
            email: user.email || "",
            notifyOfNewAgencies: user.notifyOfNewAgencies,
            notifyOfNewCastingCalls: user.notifyOfNewCastingCalls,
          }}
          onSubmit={onSubmit}
          isLoading={loading}
          error={error}
          onChangePassword={() => {}} // Not needed for user editing
          email={user.email || ""}
          logoThumbnailUrl={user.agency?.logoThumbnailUrl || null}
          isNewUser={false}
        />
      </div>
    </div>
  )
}
