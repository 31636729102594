import { Outlet } from "react-router-dom"
import { HeaderProvider } from "~/common/HeaderContext"
import { MobileWarningCard } from "~/components/MobileWarning"

export const PublicLayout = () => {
  return (
    <HeaderProvider>
      <div>
        <div className="min-h-screen bg-gray10 flex flex-col">
          <main className="flex flex-grow">
            <div className="lg:hidden">
              <MobileWarningCard />
            </div>
            <div className="hidden lg:block px-4 sm:px-6 lg:px-8 w-full">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </HeaderProvider>
  )
}
