import { useEffect } from "react"
import { Link } from "react-router-dom"
import { castingCallsMyCallsPath } from "~/common/paths"
import { BackToContext, useSetBackTo } from "~/common/useBackNavigation"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import { newCastingCallPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { numberDisplay } from "~/util/numbers"
import { SearchBar } from "./components/casting-call-filters/SearchBar"
import { useCastingCalls } from "./data/useCastingCalls"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { CastingCallRow } from "./components/casting-call-table/CastingCallRow"
import { CampaignNameCell } from "./components/casting-call-table/CampaignNameCell"
import { BudgetCell } from "./components/casting-call-table/BudgetCell"
import { CategoryCell } from "./components/casting-call-table/CategoryCell"
import { CollaborationTypeCell } from "./components/casting-call-table/CollaborationTypeCell"
import { CreatorTypeCell } from "./components/casting-call-table/CreatorTypeCell"
import { PlatformsCell } from "./components/casting-call-table/PlatformsCell"
import { DatePostedCell } from "./components/casting-call-table/DatePostedCell"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { SubmissionsCell } from "./components/casting-call-table/SubmissionsCell"

export const MyCastingCallsScreen = () => {
  useDocumentTitle("My Casting Calls")
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const currentUser = useCurrentUser()
  const {
    loading,
    data,
    castingCalls,
    pageInfo,
    onLoadNextPage,
    setSearchValues,
  } = useCastingCalls({
    myCastingCalls: true,
  })

  useEffect(() => {
    setHeaderText("My Casting Calls")
  }, [setHeaderText])

  useSetBackTo(BackToContext.CastingCall, castingCallsMyCallsPath({}))

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.castingCalls.totalCount)} Casting Calls`
    )
    return () => {
      setSubheaderText("")
    }
  }, [setSubheaderText, loading, data?.castingCalls.totalCount])

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <div className="flex justify-between items-center gap-4">
            <SearchBar setSearchValues={setSearchValues} myRoster />
            <Button
              variant="default"
              color="primary"
              className="ml-6"
              disabled={!currentUser.canCreateCastingCall.value}
            >
              <Link to={newCastingCallPath({})}>+ New Casting Call</Link>
            </Button>
          </div>
          <Card className="absolute top-[70px] bottom-4 left-6 right-6 flex-grow overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead sticky>Name</TableHead>
                  <TableHead sticky>Budget</TableHead>
                  <TableHead sticky>Category</TableHead>
                  <TableHead sticky>Type of Collaboration</TableHead>
                  <TableHead sticky>Creator Type</TableHead>
                  <TableHead sticky>Platforms</TableHead>
                  <TableHead sticky>Date Posted</TableHead>
                  <TableHead sticky># of submissions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {castingCalls.map((castingCall) => (
                  <CastingCallRow
                    castingCall={castingCall}
                    key={castingCall.id}
                  >
                    <CampaignNameCell />
                    <BudgetCell />
                    <CategoryCell />
                    <CollaborationTypeCell />
                    <CreatorTypeCell />
                    <PlatformsCell />
                    <DatePostedCell />
                    <SubmissionsCell />
                  </CastingCallRow>
                ))}
              </TableBody>
              <InfiniteLoadMore
                onEndReached={onLoadNextPage}
                canLoadMore={!loading && !!pageInfo?.hasNextPage}
                loading={loading && castingCalls.length > 0}
                className="p-4"
              />
              {loading && <div>Loading...</div>}
            </Table>
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}
