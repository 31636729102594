import { useCurrentUser } from "~/auth/CurrentUserContext"
import { publicRosterPath } from "~/common/paths"

import MediaKitIcon from "~/images/icons/media-kit.svg?react"

export const MediaKitButton = () => {
  const currentUser = useCurrentUser()

  return (
    <a
      href={publicRosterPath({
        agencyId: currentUser.agency?.id ?? "",
      })}
      className="inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 bg-gray20 text-defaultSecondary hover:bg-gray20/60 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80 rounded h-10 px-[24px] py-2 flex-row gap-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MediaKitIcon />
      <span>Media Kit</span>
    </a>
  )
}
