import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { ErrorBoundary } from "./components/ErrorBoundary"
import { AnalyticsTracking } from "./layouts/AnalyticsTracking"
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout"
import { Intercom } from "./layouts/Intercom"
import { OnboardingLayout } from "./layouts/OnboardingLayout"
import { PublicLayout } from "./layouts/PublicLayout"
import { RequireAdmin } from "./layouts/RequireAdmin"
import { RequireUserNotOnboardedAndSubscribed } from "./layouts/RequireUserNotOnboardedAndSubscribed"
import { RequireUserOnboardedAndSubscribed } from "./layouts/RequireUserOnboardedAndSubscribed"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { AccountInactiveScreen } from "./screens/AccountInactiveScreen"
import { LoadingScreen } from "./screens/LoadingScreen"
import { AdminUsersScreen } from "./screens/admin/users/AdminUsersScreen"
import { BrandsScreen } from "./screens/agencies/BrandsScreen"
import { MarketingAgenciesScreen } from "./screens/agencies/MarketingAgenciesScreen"
import { TalentAgenciesScreen } from "./screens/agencies/TalentAgenciesScreen"
import { AllCastingCallsScreen } from "./screens/casting/AllCastingCallsScreen"
import { EditCastingCallScreen } from "./screens/casting/EditCastingCallScreen"
import { FavoriteCastingCallsScreen } from "./screens/casting/FavoriteCastingCallsScreen"
import { MyCastingCallSubmissionsScreen } from "./screens/casting/MyCastingCallSubmissionsScreen"
import { MyCastingCallsScreen } from "./screens/casting/MyCastingCallsScreen"
import { NewCastingCallScreen } from "./screens/casting/NewCastingCallScreen"
import { SubmitToCastingCallScreen } from "./screens/casting/SubmitToCastingCallScreen"
import { ViewCastingCallDetailsScreen } from "./screens/casting/ViewCastingCallDetailsScreen"
import { ViewCastingCallScreen } from "./screens/casting/ViewCastingCallScreen"
import { ViewCastingCallSubmissionsScreen } from "./screens/casting/ViewCastingCallSubmissionsScreen"
import { DashboardScreen } from "./screens/dashboard/DashboardScreen"
import { OnboardingCheckoutSuccess } from "./screens/onboarding/OnboardingCheckoutSuccess"
import { OnboardingAgencyDetailsScreen } from "./screens/onboarding/agency-details/OnboardingAgencyDetailsScreen"
import { OnboardingChoosePlanScreen } from "./screens/onboarding/choose-plan/OnboardingChoosePlanScreen"
import { ForgotPasswordScreen } from "./screens/onboarding/forgot-password/ForgotPasswordScreen"
import { OnboardingPersonalDetailsScreen } from "./screens/onboarding/personal-details/OnboardingPersonalDetailsScreen"
import { ResetPasswordScreen } from "./screens/onboarding/reset-password/ResetPasswordScreen"
import { SignInScreen } from "./screens/onboarding/sign-in/SignInScreen"
import { SignUpScreen } from "./screens/onboarding/sign-up/SignUpScreen"
import { MyRosterScreen } from "./screens/rosters/my-roster/MyRosterScreen"
import { RosterDatabaseScreen } from "./screens/rosters/roster-database/RosterDatabaseScreen"
import { PublicRosterScreen } from "./screens/rosters/public-roster/PublicRosterScreen"
import { SettingsAccountScreen } from "./screens/settings/SettingsAccountScreen"
import { SettingsTeamScreen } from "./screens/settings/SettingsTeamScreen"
import { SubscriptionScreen } from "./screens/settings/subscription/SubscriptionScreen"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route element={<AnalyticsTracking />}>
        <Route element={<Intercom />}>
          <Route path={paths.loadingPath.pattern} element={<LoadingScreen />} />

          <Route element={<PublicLayout />}>
            <Route
              path={paths.publicRosterPath.pattern}
              element={<PublicRosterScreen />}
            />
          </Route>

          <Route element={<RequireUserSignedIn />}>
            <Route element={<RequireUserOnboardedAndSubscribed />}>
              <Route element={<AuthenticatedLayout />}>
                <Route
                  path={paths.dashboardPath.pattern}
                  element={<DashboardScreen />}
                />
                <Route
                  path={paths.rosterPath.pattern}
                  element={<RosterDatabaseScreen />}
                />
                <Route
                  path={paths.castingCallsPath.pattern}
                  element={<AllCastingCallsScreen />}
                />
                <Route
                  path={paths.castingCallsFavoritesPath.pattern}
                  element={<FavoriteCastingCallsScreen />}
                />
                <Route
                  path={paths.castingCallsMySubmissionsPath.pattern}
                  element={<MyCastingCallSubmissionsScreen />}
                />
                <Route
                  path={paths.castingCallsMyCallsPath.pattern}
                  element={<MyCastingCallsScreen />}
                />
                <Route
                  path={paths.newCastingCallPath.pattern}
                  element={<NewCastingCallScreen />}
                />
                <Route
                  path={paths.castingCallBasePath.pattern}
                  element={<ViewCastingCallScreen />}
                >
                  <Route index element={<Navigate to="details" replace />} />
                  <Route
                    path={paths.castingCallDetailsPath.pattern}
                    element={<ViewCastingCallDetailsScreen />}
                  />
                  <Route
                    path={paths.castingCallSubmissionsPath.pattern}
                    element={<ViewCastingCallSubmissionsScreen />}
                  />
                </Route>
                <Route
                  path={paths.editCastingCallPath.pattern}
                  element={<EditCastingCallScreen />}
                />
                <Route
                  path={paths.submitToCastingCallPath.pattern}
                  element={<SubmitToCastingCallScreen />}
                />
                <Route
                  path={paths.talentAgenciesPath.pattern}
                  element={<TalentAgenciesScreen />}
                />
                <Route
                  path={paths.marketingAgenciesPath.pattern}
                  element={<MarketingAgenciesScreen />}
                />
                <Route
                  path={paths.brandAgenciesPath.pattern}
                  element={<BrandsScreen />}
                />
                <Route
                  path={paths.myRosterPath.pattern}
                  element={<MyRosterScreen />}
                />
                <Route
                  path={paths.settingsAccountPath.pattern}
                  element={<SettingsAccountScreen />}
                />
                <Route
                  path={paths.settingsTeamPath.pattern}
                  element={<SettingsTeamScreen />}
                />
                <Route
                  path={paths.settingsSubscriptionPath.pattern}
                  element={<SubscriptionScreen />}
                />

                <Route element={<RequireAdmin />}>
                  <Route
                    path={paths.adminUsersPath.pattern}
                    element={<AdminUsersScreen />}
                  />
                </Route>
              </Route>
            </Route>

            <Route element={<RequireUserNotOnboardedAndSubscribed />}>
              <Route element={<OnboardingLayout />}>
                <Route
                  path={paths.onboardingPersonalDetailsPath.pattern}
                  element={<OnboardingPersonalDetailsScreen />}
                />
                <Route
                  path={paths.onboardingAgencyDetailsPath.pattern}
                  element={<OnboardingAgencyDetailsScreen />}
                />
                <Route
                  path={paths.onboardingChoosePlanPath.pattern}
                  element={<OnboardingChoosePlanScreen />}
                />
                <Route
                  path={paths.onboardingCheckoutSuccessPath.pattern}
                  element={<OnboardingCheckoutSuccess />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<RequireUserSignedOut />}>
            <Route
              index
              path={paths.signInPath.pattern}
              element={<SignInScreen />}
            />
            <Route path={paths.signUpPath.pattern} element={<SignUpScreen />} />
            <Route
              path={paths.forgotPasswordPath.pattern}
              element={<ForgotPasswordScreen />}
            />
            <Route
              path={paths.resetPasswordPath.pattern}
              element={<ResetPasswordScreen />}
            />
          </Route>
          <Route
            element={<AccountInactiveScreen />}
            path={paths.accountInactivePath.pattern}
          />
        </Route>
      </Route>
    </Route>
  )
)
