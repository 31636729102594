import copy from "copy-to-clipboard"
import toast from "react-hot-toast"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { publicRosterPath } from "~/common/paths"

import MediaKitIcon from "~/images/icons/media-kit.svg?react"

export const MediaKitButton = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <Button
      variant="ghost"
      onClick={(e) => {
        e.stopPropagation()
        const url = `${window.location.origin}${publicRosterPath({
          agencyId: influencer.agency?.id ?? "",
        })}?influencerId=${influencer.id}`
        copy(url)
        toast.success(`Copied URL to clipboard`)
      }}
      className="px-4 flex flex-row gap-2"
    >
      <MediaKitIcon />
      <span>Media Kit</span>
    </Button>
  )
}
