import {
  AgeRangeEnum,
  AudienceGenderEnum,
  BusinessTypeEnum,
  CategoryEnum,
  CollaborationTypeEnum,
  CreatorTypeEnum,
  DeliverableTypeEnum,
  ExclusivityEnum,
  GenderEnum,
  JobTitleEnum,
  LanguageEnum,
  PlatformEnum,
  SocialMediaRateTypeEnum,
  SubscriptionBillingCycleEnum,
} from "~/__generated__/graphql"

const enumMappingToOptions = <V extends string>(mapping: Record<V, string>) =>
  Object.entries(mapping).map(([value, label]) => ({
    value: value as V,
    label: label as string,
  }))

const enumToName = <V extends string>(mapping: Record<V, string>) => {
  return (value?: V | null) => (value ? mapping[value] : "")
}

const enumsToNames = <V extends string>(mapping: Record<V, string>) => {
  return (values?: V[] | null) =>
    values?.map((value) => mapping[value]).sort() || []
}

const ageRangeMapping: Record<AgeRangeEnum, string> = {
  UNDER_18: "Under 18",
  BETWEEN_18_24: "18 - 24",
  BETWEEN_25_34: "25 - 34",
  BETWEEN_35_44: "35 - 44",
  BETWEEN_45_54: "45 - 54",
  OVER_55: "55+",
}

const audienceGenderMapping: Record<AudienceGenderEnum, string> = {
  FEMALE: "Female",
  MALE: "Male",
}

const businessTypeMapping: Record<BusinessTypeEnum, string> = {
  BRAND: "Brand",
  MARKETING: "Marketing Agency",
  TALENT: "Talent Agency",
}

const categoryMapping: Record<CategoryEnum, string> = {
  ACADEMIA: "Academia",
  ACTING: "Acting",
  AI: "AI",
  ANIMALS_PET: "Animals/ Pet",
  ANIME: "Anime",
  ARCHITECTURE: "Architecture",
  ART: "Art",
  ASMR: "ASMR",
  BEAUTY: "Beauty",
  BIKING: "Biking",
  BOOK_REVIEWS: "Book Reviews",
  BUSINESS: "Business",
  CARS: "Cars",
  CELEBRITY: "Celebrity",
  CLEANING: "Cleaning",
  COMEDY: "Comedy",
  COOKING_BAKING: "Cooking/ Baking",
  COSPLAY: "Cosplay",
  DAD: "Dad",
  DANCE: "Dance",
  DESIGN: "Design",
  DISABILITY: "Disability",
  DIY: "DIY",
  EDUCATION: "Education",
  ENTERTAINMENT: "Entertainment",
  ESPORTS: "ESports",
  EVENT_PLANNING: "Event Planning",
  FAMILY: "Family",
  FASHION: "Fashion",
  FINANCE: "Finance",
  FITNESS: "Fitness",
  FOOD: "Food",
  GAMING: "Gaming",
  HAIRCARE: "Haircare",
  HEALTH_WELLNESS: "Health/ Wellness",
  HOME: "Home",
  INTERVIEWS: "Interviews",
  JEWELRY: "Jewelry",
  KIDS: "Kids",
  LAW: "Law",
  LIFESTYLE: "Lifestyle",
  MAGIC: "Magic",
  MENTAL_HEALTH_SELF_HELP: "Mental Health/ Self Help",
  MODELING: "Modeling",
  MOM: "Mom",
  MOVIES_SHOWS: "Movies/ Shows",
  MUSIC: "Music",
  NEWS_POLITICS: "News/ Politics",
  OUTDOORS: "Outdoors",
  PARENTING: "Parenting",
  PARTY: "Party",
  PERFORMER: "Performer",
  PHOTOGRAPHY_VIDEOGRAPHY: "Photography/ Videography",
  PODCAST: "Podcast",
  REAL_ESTATE: "Real Estate",
  RELATIONSHIPS: "Relationships",
  RELIGION: "Religion",
  REVIEWS: "Reviews",
  SCIENCE: "Science",
  SELF_DEFENSE: "Self Defense",
  SHOPPING: "Shopping",
  SIGN_LANGUAGE: "Sign Language",
  SINGING: "Singing",
  SKINCARE: "Skincare",
  SOFTWARE: "Software",
  SPORTS: "Sports",
  STORYTELLER: "Storyteller",
  STUDENT: "Student",
  STUNTS: "Stunts",
  TECHNOLOGY: "Technology",
  TRAVEL: "Travel",
  VFX: "VFX",
  WEB_DESIGN: "Web Design",
}

const collaborationTypeMapping: Record<CollaborationTypeEnum, string> = {
  AFFILIATE: "Affiliate",
  GIFTING: "Gifting",
  IRL: "IRL",
  PAID_PROMO: "Paid Promo",
  UGC: "UGC",
}

const creatorTypeMapping: Record<CreatorTypeEnum, string> = {
  INFLUENCER: "Influencer",
  UGC: "UGC",
  STREAMER: "Streamer",
  BRAND_AMBASSADOR: "Brand Ambassador",
  BLOGGER: "Blogger",
}

const deliverableTypeMapping: Record<DeliverableTypeEnum, string> = {
  TIK_TOK_VIDEO: "Video",
  TIK_TOK_LINK_IN_BIO: "Link in Bio",
  INSTAGRAM_PHOTO: "Photo",
  INSTAGRAM_REEL: "Reel",
  INSTAGRAM_STORY: "Story",
  INSTAGRAM_LINK_IN_BIO: "Link in Bio",
  YOUTUBE_INTEGRATION: "Integration",
  YOUTUBE_DEDICATION: "Dedication",
  YOUTUBE_SHORTS: "Short",
  TWITCH_STREAM: "Stream",
}

const exclusivityMapping: Record<ExclusivityEnum, string> = {
  EXCLUSIVE: "Exclusive",
  NON_EXCLUSIVE: "Non-Exclusive",
}

const genderMapping: Record<GenderEnum, string> = {
  FEMALE: "Female",
  MALE: "Male",
  NON_BINARY: "Non-Binary",
  TRANS_MAN: "Transgender Man",
  TRANS_WOMAN: "Transgender Woman",
  OTHER: "Other",
}

const jobTitleMapping: Record<JobTitleEnum, string> = {
  AGENT: "Agent",
  MANAGER: "Manager",
}

const languageMapping: Record<LanguageEnum, string> = {
  ENGLISH: "English",
  SPANISH: "Spanish",
  MANDARIN: "Mandarin",
  HINDI: "Hindi",
  FRENCH: "French",
  ARABIC: "Arabic",
  HEBREW: "Hebrew",
  RUSSIAN: "Russian",
  PORTUGUESE: "Portuguese",
  GERMAN: "German",
  DUTCH: "Dutch",
  JAPANESE: "Japanese",
  KOREAN: "Korean",
  TURKISH: "Turkish",
  ITALIAN: "Italian",
}

const platformMapping: Record<PlatformEnum, string> = {
  TIK_TOK: "TikTok",
  INSTAGRAM: "Instagram",
  YOUTUBE: "YouTube",
  TWITCH: "Twitch",
}

const socialMediaRateTypeMapping: Record<SocialMediaRateTypeEnum, string> = {
  FLAT: "Flat",
  RANGE: "Range",
}

const subscriptionBillingCycleMapping: Record<
  SubscriptionBillingCycleEnum,
  string
> = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
}

export const ageRangeName = enumToName<AgeRangeEnum>(ageRangeMapping)

export const ageRangeNames = enumsToNames<AgeRangeEnum>(ageRangeMapping)

export const ageRangeOptions =
  enumMappingToOptions<AgeRangeEnum>(ageRangeMapping)

export const audienceGenderName = enumToName<AudienceGenderEnum>(
  audienceGenderMapping
)

export const audienceGenderNames = enumsToNames<AudienceGenderEnum>(
  audienceGenderMapping
)

export const audienceGenderOptions = enumMappingToOptions<AudienceGenderEnum>(
  audienceGenderMapping
)

export const businessTypeName =
  enumToName<BusinessTypeEnum>(businessTypeMapping)

export const businessTypeOptions =
  enumMappingToOptions<BusinessTypeEnum>(businessTypeMapping)

export const categoryName = enumToName<CategoryEnum>(categoryMapping)

export const categoryNames = enumsToNames<CategoryEnum>(categoryMapping)

export const categoryOptions =
  enumMappingToOptions<CategoryEnum>(categoryMapping)

export const collaborationTypeNames = enumsToNames<CollaborationTypeEnum>(
  collaborationTypeMapping
)

export const collaborationTypeName = enumToName<CollaborationTypeEnum>(
  collaborationTypeMapping
)

export const collaborationTypeOptions =
  enumMappingToOptions<CollaborationTypeEnum>(collaborationTypeMapping)

export const creatorTypeNames =
  enumsToNames<CreatorTypeEnum>(creatorTypeMapping)

export const creatorTypeName = enumToName<CreatorTypeEnum>(creatorTypeMapping)

export const creatorTypeOptions =
  enumMappingToOptions<CreatorTypeEnum>(creatorTypeMapping)

export const deliverableTypeOptions = enumMappingToOptions<DeliverableTypeEnum>(
  deliverableTypeMapping
)

export const deliverableTypeName = enumToName<DeliverableTypeEnum>(
  deliverableTypeMapping
)

export const exclusivityName = enumToName<ExclusivityEnum>(exclusivityMapping)

export const exclusivityOptions =
  enumMappingToOptions<ExclusivityEnum>(exclusivityMapping)

export const genderName = enumToName<GenderEnum>(genderMapping)

export const genderNames = enumsToNames<GenderEnum>(genderMapping)

export const genderOptions = enumMappingToOptions<GenderEnum>(genderMapping)

export const jobTitleOptions =
  enumMappingToOptions<JobTitleEnum>(jobTitleMapping)

export const languageNames = enumsToNames<LanguageEnum>(languageMapping)

export const languageOptions =
  enumMappingToOptions<LanguageEnum>(languageMapping)

export const platformName = enumToName<PlatformEnum>(platformMapping)

export const platformOptions =
  enumMappingToOptions<PlatformEnum>(platformMapping)

export const socialMediaRateTypeOptions =
  enumMappingToOptions<SocialMediaRateTypeEnum>(socialMediaRateTypeMapping)

export const subscriptionBillingCycleName =
  enumToName<SubscriptionBillingCycleEnum>(subscriptionBillingCycleMapping)
