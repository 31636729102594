import { gql } from "~/__generated__"

export const FULL_CASTING_CALL_FRAGMENT = gql(`
  fragment FullCastingCall on CastingCall {
    id
    visible
    campaignName
    collaborationType
    influencerSubmissionDueDate
    postDate
    draftDueDate
    budgetMinCents
    budgetMaxCents
    budgetNotes
    createdAt
    deliverables {
      platform
      deliverableType
      quantity
    }
    deliverableQuantity
    deliverableUsageRights
    deliverableNotes
    influencerAge {
      min
      max
    }
    influencerCategories
    influencerCreatorTypes
    influencerCountryCodes
    influencerCountriesData {
      name
      emojiFlag
    }
    influencerGenders
    influencerLanguages
    influencerNotes
    audienceDemographicCountryCodes
    audienceDemographicCountriesData {
      name
    }
    audienceDemographicAgeRanges
    audienceDemographicGenders
    tikTokEnabled
    tikTokFollowers {
      min
      max
    }
    tikTokAverageViews {
      min
      max
    }
    tikTokEngagementRate {
      min
      max
    }
    instagramEnabled
    instagramFollowers {
      min
      max
    }
    instagramAverageViews {
      min
      max
    }
    instagramEngagementRate {
      min
      max
    }
    youtubeEnabled
    youtubeSubscribers {
      min
      max
    }
    youtubeAverageVideoViews {
      min
      max
    }
    youtubeVideoEngagementRate {
      min
      max
    }
    youtubeAverageShortsViews {
      min
      max
    }
    youtubeShortsEngagementRate {
      min
      max
    }
    twitchEnabled
    twitchSubscribers {
      min
      max
    }
    twitchAverageStreamViews {
      min
      max
    }
    twitchConcurrentViewers {
      min
      max
    }
    agency {
      id
      name
      businessType
      agentName
      phone
      contactEmail
    }
    submissionsCount
    canUpdate {
      value
    }
    canDestroy {
      value
    }
    canCreateBatch {
      value
      reasons {
        details
        fullMessages
      }
    }
    canViewInfluencers {
      value
    }
  }
`)

export const SIMPLE_CASTING_CALL_FRAGMENT = gql(`
  fragment SimpleCastingCall on CastingCall {
    id
    campaignName
    budgetMinCents
    budgetMaxCents
    influencerCategories
    collaborationType
    influencerCreatorTypes
    deliverables {
      platform
    }
    agency {
      id
      name
      logoThumbnailUrl
    }
    submissionsCount
    createdAt
  }
`)

export const CASTING_CALLS_QUERY_DOCUMENT = gql(`
  query CastingCallsScreen(
    $mySubmissions: Boolean
    $myCastingCalls: Boolean
    $filters: CastingCallsFiltersInput,
    $sortField: CastingCallSortFieldEnum,
    $sortDirection: SortDirectionEnum,

    $first: Int,
    $castingCallsCursor: String
  ) {
    castingCalls(
      mySubmissions: $mySubmissions
      myCastingCalls: $myCastingCalls
      filters: $filters
      sortField: $sortField
      sortDirection: $sortDirection

      first: $first
      after: $castingCallsCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...SimpleCastingCall
        }
      }
    }
  }
`)

export const VIEW_CASTING_CALL_QUERY = gql(`
  query CastingCall($castingCallId: ID!) {
    castingCall(castingCallId: $castingCallId) {
      ...FullCastingCall
    }
  }
`)

export const CREATE_CASTING_CALL_MUTATION = gql(`
  mutation CreateCastingCall($input: CastingCallCreateInput!) {
    castingCallCreate(input: $input) {
      castingCall {
        ...FullCastingCall
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)

export const UPDATE_CASTING_CALL_MUTATION = gql(`
  mutation UpdateCastingCall($input: CastingCallUpdateInput!) {
    castingCallUpdate(input: $input) {
      castingCall {
        ...FullCastingCall
      }
    }
  }
`)

export const DELETE_CASTING_CALL_MUTATION = gql(`
  mutation DeleteCastingCall($input: CastingCallDeleteInput!) {
    castingCallDelete(input: $input) {
      castingCall {
        id
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)
export const CASTING_CALL_BATCH_CREATE_MUTATION = gql(`
  mutation CastingCallBatchCreate($input: CastingCallBatchCreateInput!) {
    castingCallBatchCreate(input: $input) {
      castingCall {
        id
      }
    }
  }
`)
