import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      ...CurrentUser
    }
  }
`)

export const CURRENT_SUBSCRIPTION_FRAGMENT = gql(`
  fragment CurrentSubscription on Subscription {
    id
    status
    tier {
      id
      key
      name
      billingCycle
    }
    createdAt
    currentPeriodStart
    currentPeriodEnd
    cancelAtPeriodEnd
  }
`)

export const CURRENT_USER_FRAGMENT = gql(`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    jobTitle
    isAdmin
    isOnboarded
    agencyBusinessType
    discarded
    createdAt
    notifyOfNewAgencies
    notifyOfNewCastingCalls
    agencyRole
    agency {
      id
      name
      contactEmail
      agentName
      website
      phone
      logoThumbnailUrl
      businessType
      businessRelationship
      canManageSubscriptions {
        value
        ...AuthorizationError
      }
    }
    activeSubscription {
      ...CurrentSubscription
    }
    canIndexRosterDatabase {
      value
      ...AuthorizationError
    }
    canIndexMyRoster {
      value
      ...AuthorizationError
    }
    canEditBusinessType {
      value
      ...AuthorizationError
    }
    canCreateInfluencer {
      value
      ...AuthorizationError
    }
    canIndexCastingCalls {
      value
      ...AuthorizationError
    }
    canIndexMyCastingCallSubmissions {
      value
      ...AuthorizationError
    }
    canIndexMyCastingCalls {
      value
      ...AuthorizationError
    }
    canCreateCastingCall {
      value
      ...AuthorizationError
    }
    canInviteUser {
      value
    }
  }
`)
