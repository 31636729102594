import { createContext, useContext, ReactNode } from "react"

export type PageVisibility = "public" | "private"

interface PageVisibilityContextType {
  visible: PageVisibility
}

const PageVisibilityContext = createContext<
  PageVisibilityContextType | undefined
>(undefined)

interface PageVisibilityProviderProps {
  visible: PageVisibility
  children: ReactNode
}

export const PageVisibilityProvider = ({
  visible,
  children,
}: PageVisibilityProviderProps) => {
  return (
    <PageVisibilityContext.Provider value={{ visible }}>
      {children}
    </PageVisibilityContext.Provider>
  )
}

export const usePageVisibility = (): PageVisibility => {
  const context = useContext(PageVisibilityContext)
  return context?.visible ?? "private"
}
