import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { FragmentType, getFragmentData } from "~/__generated__"
import { usePageVisibility } from "~/contexts/PageVisibilityContext"
import { ChartContainer, ChartLegend } from "~/shadcn/ui/chart"
import { Progress } from "~/shadcn/ui/progress"
import Text from "~/ui/typography"
import { PROFILE_ANALYTICS_FRAGMENT } from "../../api"
import { Label } from "./Label"
import { LabelHeader } from "./LabelHeader"

const GENDER_COLORS = {
  male: "#93c5fd",
  female: "#fda4af",
  other: "#fcd34d",
}

export const AudienceDemographics = (props: {
  analytics: FragmentType<typeof PROFILE_ANALYTICS_FRAGMENT> | undefined | null
}) => {
  const analytics = getFragmentData(PROFILE_ANALYTICS_FRAGMENT, props.analytics)
  const pageVisibility = usePageVisibility()

  if (analytics === undefined) {
    return (
      <div className="mt-6">
        <LabelHeader>Audience Demographics</LabelHeader>
        <div className="text-gray-500 italic mt-2">
          We're working on getting the data, check back soon!
        </div>
      </div>
    )
  }

  const genderData = analytics
    ? analytics.genderDistribution?.map(({ gender, value }) => ({
        name: gender.toLowerCase(),
        value,
        color:
          GENDER_COLORS[gender.toLowerCase() as keyof typeof GENDER_COLORS] ||
          GENDER_COLORS.other,
      })) || []
    : [
        { name: "male", value: 0, color: GENDER_COLORS.male },
        { name: "female", value: 0, color: GENDER_COLORS.female },
        { name: "other", value: 0, color: GENDER_COLORS.other },
      ]

  const ageData = analytics?.genderAgeDistribution
    ?.reduce(
      (acc, { ageRange, percentage }) => {
        const existingRange = acc.find((item) => item.name === ageRange)
        if (existingRange) {
          existingRange.total = (Number(existingRange.total) || 0) + percentage
        } else {
          acc.push({
            name: ageRange,
            total: percentage,
          })
        }
        return acc
      },
      [] as Array<{ name: string; total: number }>
    )
    .sort((a, b) => {
      // Sort age ranges in ascending order
      const aAge = parseInt(a.name.match(/\d+/)?.[0] || "0")
      const bAge = parseInt(b.name.match(/\d+/)?.[0] || "0")
      return aAge - bAge
    }) || [
    { name: "13-17", total: 0 },
    { name: "18-24", total: 0 },
    { name: "25-34", total: 0 },
    { name: "35-44", total: 0 },
    { name: "45+", total: 0 },
  ]

  const countryData = analytics?.countries
    ?.map(({ country, percentage }) => ({
      name: `${country.emojiFlag} ${country.name}`,
      value: percentage,
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 4) || [
    { name: "-", value: 0 },
    { name: "-", value: 0 },
    { name: "-", value: 0 },
    { name: "-", value: 0 },
  ]

  const cityData =
    analytics?.cities
      ?.map(({ name, value }) => ({
        name,
        value, // Value is already a percentage
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 4) || []

  const isAllowed =
    analytics &&
    (analytics.canViewAudienceData.value === true ||
      pageVisibility === "public")

  return (
    <div className="mt-6">
      <div className="flex gap-2 justify-between">
        <LabelHeader>Audience Demographics</LabelHeader>
        {analytics === null ? (
          <div>
            🕒{" "}
            <Text as="span" variant="body3" className="italic text-gray-5a">
              We’re working on getting the data, check back soon!
            </Text>
          </div>
        ) : analytics && !isAllowed ? (
          <div>
            <Text as="span" variant="body3" className="italic text-gray-5a">
              Audience demographics are not available on this subscription plan.
            </Text>
          </div>
        ) : null}
      </div>

      <div className="grid grid-cols-4 gap-8 mb-6">
        <div>
          <Label>Gender</Label>
          <ChartContainer
            className="w-[143px] h-[230px] flex justify-start"
            config={{
              male: { color: GENDER_COLORS.male },
              female: { color: GENDER_COLORS.female },
              other: { color: GENDER_COLORS.other },
            }}
          >
            <PieChart>
              <Tooltip
                content={({ payload }) => {
                  if (!payload?.length) return null
                  const entry = payload[0]?.payload
                  if (!entry.name) return null
                  return (
                    <div className="bg-white p-2 border rounded shadow-sm">
                      <div style={{ color: entry.color }}>
                        {entry.name.charAt(0).toUpperCase() +
                          entry.name.slice(1)}
                        : {entry.value.toFixed(0)}%
                      </div>
                    </div>
                  )
                }}
              />
              {genderData.every((entry) => entry.value === 0) ? (
                <Pie
                  data={[{ value: 100 }]}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={117 / 2}
                  outerRadius={143 / 2}
                  fill="#e2e2e2"
                />
              ) : (
                <Pie
                  data={genderData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={117 / 2}
                  outerRadius={143 / 2}
                >
                  {genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              )}
              <ChartLegend
                content={
                  <div className="flex flex-col gap-1 mt-auto flex-1">
                    {genderData.map((entry, index) => (
                      <div
                        key={index}
                        className="flex flex-1 items-center gap-2"
                      >
                        <div
                          className="w-3 h-3 rounded-full"
                          style={{ backgroundColor: entry.color }}
                        />
                        <div className="text-sm flex flex-1 gap-2 pe-4">
                          {entry.name.charAt(0).toUpperCase() +
                            entry.name.slice(1)}
                          {!genderData.every((entry) => entry.value === 0) && (
                            <>
                              :
                              <div className="ml-auto">
                                {entry.value.toFixed(0)}%
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                }
              />
            </PieChart>
          </ChartContainer>
        </div>
        <div>
          <Label>Top Countries</Label>
          <div className="space-y-4 mt-4">
            {countryData.map((country) => (
              <div key={country.name} className="space-y-1">
                <div className="flex justify-between text-sm">
                  <span>{country.name}</span>
                  <span>{country.value.toFixed(0)}%</span>
                </div>
                <Progress value={country.value} />
              </div>
            ))}
          </div>
        </div>
        {cityData.length > 0 ? (
          <div>
            <Label>Top Cities</Label>
            <div className="space-y-4 mt-4">
              {cityData.map((city) => (
                <div key={city.name} className="space-y-1">
                  <div className="flex justify-between text-sm">
                    <span>{city.name}</span>
                    <span>{city.value.toFixed(2)}%</span>
                  </div>
                  <Progress value={city.value} />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div>
          <Label>Age</Label>
          <ChartContainer
            className="w-full h-[230px]"
            config={{
              male: { color: GENDER_COLORS.male },
              female: { color: GENDER_COLORS.female },
              other: { color: GENDER_COLORS.other },
            }}
          >
            <BarChart
              data={ageData}
              margin={{ right: 0, left: 0, bottom: -8, top: 14 }}
            >
              <XAxis dataKey="name" />
              <YAxis hide={true} />
              <Tooltip
                cursor={{ fill: "#fff" }}
                formatter={(value: number, name: string, props: any) => {
                  if (name === "total") {
                    return [`${value.toFixed(0)}% Total`, "Total"]
                  }
                  return [
                    `${value.toFixed(0)}%`,
                    name?.charAt(0)?.toUpperCase() + name.slice(1),
                  ]
                }}
                content={({ payload }) => {
                  if (!payload?.length) return null
                  const total = payload[0]?.payload?.total
                  return (
                    <div className="bg-white p-2 border rounded shadow-sm">
                      <div className="font-medium mb-1">
                        Total: {total?.toFixed(0)}%
                      </div>
                      {payload.map(
                        (entry: any, index: number) =>
                          entry.dataKey !== "total" && (
                            <div key={index} style={{ color: entry.color }}>
                              {entry.name?.charAt(0)?.toUpperCase() +
                                entry.name.slice(1)}
                              : {entry.value?.toFixed(0)}%
                            </div>
                          )
                      )}
                    </div>
                  )
                }}
              />
              <Bar
                dataKey="total"
                fill={"#53B7AB"}
                barSize={13}
                isAnimationActive={false}
              >
                {!ageData.every((d) => d.total === 0) && (
                  <LabelList
                    dataKey="total"
                    position="top"
                    formatter={(value: number) => `${value.toFixed(0)}%`}
                  />
                )}
              </Bar>
            </BarChart>
          </ChartContainer>
        </div>
      </div>
    </div>
  )
}
