import { useEffect, useState } from "react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Card, CardContent } from "~/shadcn/ui/card"
import { numberDisplay } from "~/util/numbers"
import { useInfluencers } from "../useInfluencers"
import { InfluencersTable } from "./components/InfluencersTable"
import { NoInfluencersView } from "./components/NoInfluencersView"
import { InfluencerActionsProvider } from "./components/InfluencerActionsContext"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { MediaKitButton } from "./components/MediaKitButton"

export const MyRosterScreen = () => {
  useDocumentTitle("My Roster")
  const currentUser = useCurrentUser()
  const { setHeaderText, setSubheaderText, setHeaderBar } = useHeaderContext()
  const useInfluencersResult = useInfluencers({
    myRoster: true,
  })
  const { loading, data, influencers, isFiltering } = useInfluencersResult
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  useEffect(() => {
    setHeaderBar(
      currentUser.agency ? (
        <div className="flex-grow flex justify-end">
          <MediaKitButton />
        </div>
      ) : null
    )
    return () => {
      setHeaderBar(null)
    }
  }, [setHeaderBar, currentUser.agency])

  useEffect(() => {
    setHeaderText("My Roster")
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.influencers.totalCount)} Influencers`
    )
  }, [setSubheaderText, loading, data?.influencers.totalCount])

  useEffect(() => {
    if (!loading) {
      setInitiallyLoaded(true) // after the first load, the InfluencersTable will take over showing the spinner
    }
  }, [loading, initiallyLoaded])

  return (
    <InfluencerActionsProvider>
      <div className="h-full pb-4 relative">
        <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
          <CardContent className="flex-1 p-6 pt-4 flex flex-col">
            {!initiallyLoaded && loading ? (
              <LoadingIndicatorCentered />
            ) : influencers.length === 0 && !isFiltering && !loading ? (
              <NoInfluencersView />
            ) : (
              <InfluencersTable useInfluencersResult={useInfluencersResult} />
            )}
          </CardContent>
        </Card>
      </div>
    </InfluencerActionsProvider>
  )
}
